



















import { SearchPage } from '@/interfaces/searchPage';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class Pagination extends Vue {

  @Prop({ type: Number, required: true })
  currentPage!: number;

  @Prop({ type: Number, required: true })
  pageSize!: number;

  @Prop({ type: Number, required: true })
  totalRecords!: number;

  @Prop({ type: Number, required: false, default: () => 10 })
  maxLength!: number;

  @Prop({ type: Boolean, required: false, default: true })
  allowPageSizeChange!: boolean;

  @Prop({ type: Array, required: false, default: () => [10, 20, 50, 100] })
  presetSizes!: number[];


  @Emit('page-changed')
  onPageChanged(currentPage) {
    return new SearchPage(this.pageSize, currentPage);
  }

  @Emit('page-changed')
  onPageSizeChanged(pageSize) {

    const searchPage = {
      currentPage: 1,
      pageIndex: 0,
      pageSize: pageSize,
    };

    return searchPage;
  }

  get length() {
    return Math.ceil(this.totalRecords / this.pageSize);
  }
}
