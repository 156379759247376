





































































































































































































































































































































import AdminResignationStoreMixin from '../../mixins/AdminResignationStoreMixin';
import Component, { mixins } from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import EnumsMixin from '@/mixins/EnumsMixin';
import Pagination from '@/components/Pagination.vue';
import { SearchPage } from '@/interfaces/searchPage';
import { ResignationQueueStatus } from '@/interfaces/_auto/api';
import { IExpandedEnum } from '@/interfaces/IExpandedEnum';
import { AdminResignationQueueApi } from '@/api/adminResignationQueueApi';

@Component({
  components: {
    Pagination,
  },
})
export default class ResignationQueue extends mixins(AdminResignationStoreMixin, EnumsMixin) {

  jumpToResignationId: string | null = null;

  adminSummary: any = null;

  resignationListHeaders: DataTableHeader[] = [
    {
      value: 'resignationId',
      text: 'ID',
    },
    {
      value: 'fullName',
      text: 'Name',
    },
    {
      value: 'location',
      text: 'Location',
    },
    {
      value: 'birthDate',
      text: 'Birth Date',
    },
    {
      value: 'userApprovedResignationDate',
      text: 'User Approved',
    },
    {
      value: 'mostRecentlySubmittedToMembershipDate',
      text: 'Last Submitted',
    },
    {
      value: 'confirmationReceivedDate',
      text: 'Confirm Rcd',
    },
  ];

  holdFlagOptions = [
    {
      text: 'Not on hold',
      value: false,
    },
    {
      text: 'On hold',
      value: true,
    },
  ];

  duplicateFlagOptions = [
    {
      text: 'Not marked as duplicate',
      value: false,
    },
    {
      text: 'Marked as duplicate',
      value: true,
    },
  ];

  errorFlagOptions = [
    {
      text: 'Not marked as error',
      value: false,
    },
    {
      text: 'Error',
      value: true,
    },
  ];

  hasApprovedDocumentsOptions = [
    {
      text: 'Does not have any approved',
      value: false,
    },
    {
      text: 'Has approved docs',
      value: true,
    },
  ];

  async mounted() {
    await this.loadAdminEnums();

    // load presets from query
    const { status, hold } = this.$route.query;

    if (status) {
      const foundStatus = this._.find(this.adminEnums.resignationQueueStatusOptions, e => e.value === status);
      if (foundStatus) {
        this.resignationQueueStatus = foundStatus;
      }

      this.holdFlag = hold;
    }


    // await this.changePage({ pageSize: 20, currentPage: 1 });
    // if (this.searchPage.pageSize === 1) {
    // }

    await this.searchResignationQueue();
    await this.getAdminSummary();
  }

  async getAdminSummary() {
    const { data } = await AdminResignationQueueApi.getAdminSummary();
    this.adminSummary = data;
  }

  onResignationPageChanged(searchPage: SearchPage) {
    this.changePage(searchPage);
    this.searchResignationQueue();
  }


  getResignationQueueStatusOption(status: ResignationQueueStatus): IExpandedEnum<ResignationQueueStatus> | null {
    const found = this.adminEnums.resignationQueueStatusOptions.find(f => (f as unknown as IExpandedEnum<ResignationQueueStatus>).number === status);

    if (!found) {
      return null;
    }

    return found;
  }


  async setSearchPreset(presetName: string) {

    switch (presetName) {
      case 'all':
        this.resignationQueueStatus = null;
        this.holdFlag = null;
        this.duplicateFlag = null;
        this.errorFlag = null;
        this.hasApprovedDocuments = null;
        break;

      case 'needsApproval':
        this.resignationQueueStatus = this.getResignationQueueStatusOption(ResignationQueueStatus.AwaitingAttorneyReview);
        this.holdFlag = false;
        this.duplicateFlag = false;
        this.errorFlag = false;
        this.hasApprovedDocuments = null;
        break;

      case 'needsSendKmc':
        this.resignationQueueStatus = this.getResignationQueueStatusOption(ResignationQueueStatus.WaitingForSendToKmc);
        this.holdFlag = false;
        this.duplicateFlag = false;
        this.errorFlag = false;
        this.hasApprovedDocuments = null;
        break;

      case 'needsConfirmation':
        this.resignationQueueStatus = this.getResignationQueueStatusOption(ResignationQueueStatus.WaitingForConfirmation);
        this.holdFlag = false;
        this.duplicateFlag = false;
        this.errorFlag = false;
        this.hasApprovedDocuments = null;
        break;

      case 'confirmed':
        this.resignationQueueStatus = this.getResignationQueueStatusOption(ResignationQueueStatus.Confirmed);
        this.holdFlag = false;
        this.duplicateFlag = false;
        this.errorFlag = false;
        this.hasApprovedDocuments = null;
        break;

      case 'onHold':
        this.resignationQueueStatus = null;
        this.holdFlag = true;
        this.duplicateFlag = null;
        this.errorFlag = null;
        this.hasApprovedDocuments = null;
        break;

      case 'duplicates':
        this.resignationQueueStatus = null;
        this.holdFlag = null;
        this.duplicateFlag = true;
        this.errorFlag = null;
        this.hasApprovedDocuments = null;
        break;

      case 'errors':
        this.resignationQueueStatus = null;
        this.holdFlag = null;
        this.duplicateFlag = null;
        this.errorFlag = true;
        this.hasApprovedDocuments = null;
        break;

      case 'docReview':
        this.resignationQueueStatus = this.getResignationQueueStatusOption(ResignationQueueStatus.WaitingForSendToKmc);
        this.holdFlag = false;
        this.duplicateFlag = false;
        this.errorFlag = false;
        this.hasApprovedDocuments = false;
        break;
    }

    await this.resetPageAndSearch();
  }

  getResignationAdminRoute(resignation) {
    return {
      name: 'admin-resignation-detail',
      params: {
        resignationId: resignation.resignationId,
      },
      query: {
        position: this.resignationList.indexOf(resignation) + 1,
      },
    };
  }

  async jumpToResignation() {
    if (this.jumpToResignationId) {

      if (this.jumpToResignationId.startsWith('Q') || this.jumpToResignationId.startsWith('q')) {
        this.jumpToResignationId = this.jumpToResignationId.substr(1, this.jumpToResignationId.length - 1);
      }
      await this.$router.push({
        name: 'admin-resignation-detail',
        params: { resignationId: this.jumpToResignationId },
      });
    }
  }

}

/*
export default {
  name: 'ResignationQueue',
  mixin: [],
  data() {
    return {

      resignationListLoading: false,

    };
  },
  ,
  methods: {
    async searchResignationQueue() {
      await this.$store.commit('adminResignationStore/savePage', 1);
      await this.$store.dispatch('adminResignationStore/searchResignationQueue');
    },
    async resignationPageChanged(currentPage) {
      await this.$store.dispatch('adminResignationStore/pageChanged', currentPage);
    },
  },
  computed: {
    ...mapState('enums', ['adminEnums']),
    ...mapState('adminResignationStore', ['totalRecords', 'resignationList', 'searchPage']),

    searchTerm: {
      get() {
        return this.$store.state.adminResignationStore.searchTerm;
      },
      set(value) {
        this.$store.commit('adminResignationStore/updateSearchTerm', value);
      },
    },
    holdFlag: {
      get() {
        return this.$store.state.adminResignationStore.holdFlag;
      },
      set(value) {
        this.$store.commit('adminResignationStore/updateHoldFlag', value);
      },
    },
    duplicateFlag: {
      get() {
        return this.$store.state.adminResignationStore.duplicateFlag;
      },
      set(value) {
        this.$store.commit('adminResignationStore/updateDuplicateFlag', value);
      },
    },
    errorFlag: {
      get() {
        return this.$store.state.adminResignationStore.errorFlag;
      },
      set(value) {
        this.$store.commit('adminResignationStore/updateErrorFlag', value);
      },
    },
    resignationQueueStatus: {
      get() {
        return this.$store.state.adminResignationStore.resignationQueueStatus;
      },
      set(value) {
        this.$store.commit('adminResignationStore/updateResignationQueueStatus', value);
      },
    },
  },
};
*/
